import React, { useEffect } from 'react';
import '../../style/App.scss';
import { Box, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useNavigationStore from '../../store/zustand/navigation';
import logo from '../../assets/images/Bluberry_Logo.png';

export const MainContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  gap: theme.spacing(2),
  height: '100%',
  justifyContent: 'center',
  padding: theme.spacing(1),
  width: '100%'
}));

const Landing: React.FC = function Landing() {
  const navigation = useNavigationStore((state) => state);
  const { t } = useTranslation();

  // ====================================================== LIFECYCLE
  useEffect(() => {
    navigation.setCurrentStepScreen('landing');
  }, []);

  return (
    <MainContainer>
      <img
        alt="logo"
        className="logo-image"
        src={logo}
        style={{ width: 200 }}
      />
    </MainContainer>
  );
};

export default Landing;
