/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import {
  Typography,
  Box,
  Checkbox,
  Popover,
  TextField,
  styled,
  Button,
  Container
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import useSWR, { useSWRConfig } from 'swr';
import {
  DayCheckBox,
  DayCheckBoxSelected,
  GridBox,
  GridBoxContainer
} from './parts';
import ButtonFormGroup from '../form/ButtonFormGroup';
import { weekDays } from '../../helpers/weekDays';
import api from '../../api';
import { FormLabel, WhiteBoxContainer } from '../form/parts';
import {
  convertUTCToLocalTimeZone,
  timeTimezoneToUTC
} from '../../utils/general';

const TimePickerFileld = styled(TextField)(() => ({
  '.MuiOutlinedInput-notchedOutline': {
    borderStyle: 'hidden'
  },
  borderStyle: 'hidden',
  fontSize: '15px',
  marginTop: '10px',
  width: '100%'
}));

const style = {
  fontSize: '14px',
  fontWeight: 'bold',
  padding: '20px'
};

interface Props {
  callBack?: (values: any) => void;
  diabledButton?: boolean;
  enable?: boolean;
  externalFormValue?: any;
  isMobile: boolean;
  profile: any;
  setFormValueExternal?: any;
  setTime?: any;
  type: 'profile' | 'company' | 'myProfile';
}

interface RequestProps {
  requestedAvailableDays: any[];
  requestedTimeEnd: string;
  requestedTimeStart: string;
}

const AvailableDaysForm: React.FC<Props> = function AvailableDaysForm({
  isMobile,
  diabledButton,
  enable,
  setTime,
  profile,
  setFormValueExternal,
  externalFormValue,
  type,
  callBack
}) {
  // ---------------------- Constants ----------------------------
  const { t } = useTranslation();
  const [updateFlag, setUpdateFlag] = useState<boolean>(false);
  const [requestMsg, setRequestMsg] = useState<boolean>(false);
  // API triggers
  const [fireApiGetRequest, setFireApiGetRequest] = useState<boolean>(true);
  const [fireApiCancelRequest, setFireApiCancelRequest] =
    useState<boolean>(false);
  const [fireApiSetRequest, setFireApiSetRequest] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  // Pop over controller
  const label = { inputProps: { 'aria-label': 'Checkbox date' } };
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  // State management
  const [userWeekDay, setUserWeekDay] = useState<string[]>(['']);
  const [requestValue, setRequestValue] = useState<any>(undefined);
  const [timeStart, setTimeStart] = React.useState<Dayjs | null>(
    dayjs().hour(8).minute(0)
  );
  const [timeEnd, setTimeEnd] = React.useState<Dayjs | null>(
    dayjs().hour(17).minute(0)
  );
  const [formValue, setFormValue] = useState<RequestProps>({
    requestedAvailableDays: [],
    requestedTimeEnd: '',
    requestedTimeStart: ''
  });
  const [formValueToSendRequest, serFormValueToSendRequest] =
    useState<RequestProps>({
      requestedAvailableDays: [],
      requestedTimeEnd: '',
      requestedTimeStart: ''
    });

  // ------------------------ API ---------------------------
  const { data: dataGetRequest, error: errorDataGetRequest } = useSWR(
    fireApiGetRequest ? 'get/request' : null,
    api.authApi.getRequestChangeTime
  );

  const { data: dataCancelRequest, error: errorDataCancelRequest } = useSWR(
    fireApiCancelRequest ? requestValue?.id : null,
    api.authApi.setCancelRequestChangeTime
  );

  const { data: dataSetRequest, error: errorDataSetRequest } = useSWR(
    fireApiSetRequest ? formValueToSendRequest : null,
    api.authApi.setRequestChangeTime
  );

  const { mutate } = useSWRConfig();
  const clearGetRequest = () => mutate('get/request', null, true);

  // -------------------- Functions -------------------------

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (value: string) => {
    if (userWeekDay?.includes(value)) {
      setUserWeekDay(userWeekDay.filter((item: string) => item !== value));
      return;
    }
    setUserWeekDay([...userWeekDay, value]);
  };

  const formatTime = (time: Dayjs | null) => {
    if (
      time?.hour().toString().length === 1 &&
      time?.minute().toString().length === 1
    ) {
      return `0${time?.hour()}:0${time?.minute()}`;
    }
    if (time?.hour().toString().length === 1) {
      return `0${time?.hour()}:${time?.minute()}`;
    }
    if (time?.minute().toString().length === 1) {
      return `${time?.hour()}:0${time?.minute()}`;
    }
    return `${time?.hour()}:${time?.minute()}`;
  };

  const format12Hours = (time: string) => {
    return dayjs(`1/1/1 ${time}`).format('hh:mm a');
  };

  const handleSubmit = () => {
    if (!timeStart || !timeEnd) {
      setError(true);
      setErrorMessage(t('PROFILE.hoursValidations') as unknown as string);
      return;
    }
    if (userWeekDay) {
      const finalDayArray = userWeekDay.filter((item: string) => item !== '');
      if (finalDayArray.length === 0) {
        setError(true);
        setErrorMessage(t('PROFILE.daysValidations') as unknown as string);
        return;
      }
      if (finalDayArray.length === 0) {
        setError(true);
        setErrorMessage(t('PROFILE.daysValidations') as unknown as string);
        return;
      }
      setError(false);
      const newValues = {
        ...formValue,
        requestedAvailableDays: finalDayArray,
        requestedTimeEnd: formatTime(timeEnd),
        requestedTimeStart: formatTime(timeStart)
      };
      const newValuesUTC = {
        ...formValue,
        requestedAvailableDays: finalDayArray,
        requestedTimeEnd: timeTimezoneToUTC(
          formatTime(timeEnd),
          profile.timezoneIana
        ),
        requestedTimeStart: timeTimezoneToUTC(
          formatTime(timeStart),
          profile.timezoneIana
        )
      };
      setFormValue(newValues);
      serFormValueToSendRequest(newValuesUTC);
      setTimeout(() => {
        if (callBack) {
          callBack(newValuesUTC);
        } else {
          setFireApiSetRequest(true);
        }
      }, 300);
    }
  };

  const formatDays = (array: any[]) => {
    return array.map((item: string) => item.replace(' ', ''));
  };

  const clearTimesToActual = () => {
    if (type === 'profile' || type === 'myProfile') {
      if (
        profile &&
        profile.availableTimeStart &&
        profile.availableTimeEnd &&
        profile.availableDays
      ) {
        const availableTimeStartConvertedToTimeZone = profile.timezoneIana
          ? convertUTCToLocalTimeZone(
              profile.availableTimeStart,
              profile.timezoneIana
            )
          : profile.availableTimeStart;
        const availableTimeEndConvertedToTimeZone = profile.timezoneIana
          ? convertUTCToLocalTimeZone(
              profile.availableTimeEnd,
              profile.timezoneIana
            )
          : profile.availableTimeEnd;

        setTimeStart(
          dayjs()
            .hour(
              parseFloat(availableTimeStartConvertedToTimeZone?.split(':')[0])
            )
            .minute(
              parseFloat(availableTimeStartConvertedToTimeZone?.split(':')[1])
            )
        );
        setTimeEnd(
          dayjs()
            .hour(
              parseFloat(availableTimeEndConvertedToTimeZone?.split(':')[0])
            )
            .minute(
              parseFloat(availableTimeEndConvertedToTimeZone?.split(':')[1])
            )
        );
        setUserWeekDay(formatDays(profile.availableDays));
      }
    } else if (
      profile &&
      profile.defaultResourceTimeStart &&
      profile.defaultResourceTimeEnd &&
      profile.defaultAvailableDays
    ) {
      const availableTimeStartConvertedToTimeZone = convertUTCToLocalTimeZone(
        profile.defaultResourceTimeStart
      );
      const availableTimeEndConvertedToTimeZone = convertUTCToLocalTimeZone(
        profile.defaultResourceTimeEnd
      );
      setTimeStart(
        dayjs()
          .hour(
            parseFloat(availableTimeStartConvertedToTimeZone?.split(':')[0])
          )
          .minute(
            parseFloat(availableTimeStartConvertedToTimeZone?.split(':')[1])
          )
      );
      setTimeEnd(
        dayjs()
          .hour(parseFloat(availableTimeEndConvertedToTimeZone?.split(':')[0]))
          .minute(
            parseFloat(availableTimeEndConvertedToTimeZone?.split(':')[1])
          )
      );
      setUserWeekDay(formatDays(profile.defaultAvailableDays));
    }
  };

  // ------------------ useEffects ------------------------

  useEffect(() => {
    if (dataGetRequest) {
      const requestValue = dataGetRequest.filter(
        (ele: any) => ele.status === 'Pending'
      );
      if (requestValue.length === 0) {
        setRequestValue([]);
        setRequestMsg(false);
      } else {
        let value = requestValue[0];
        const requestedTimeStartConvertedToTimeZone = profile.timezoneIana
          ? convertUTCToLocalTimeZone(
              value.requestedTimeStart,
              profile.timezoneIana
            )
          : value.availableTimeStart;
        const requestedTimeEndConvertedToTimeZone = profile.timezoneIana
          ? convertUTCToLocalTimeZone(
              value.requestedTimeEnd,
              profile.timezoneIana
            )
          : value.requestedTimeEnd;
        value = {
          ...value,
          requestedTimeEnd: requestedTimeEndConvertedToTimeZone,
          requestedTimeStart: requestedTimeStartConvertedToTimeZone
        };
        setRequestValue(value);
        setRequestMsg(true);
      }
      setFireApiGetRequest(false);
    }
  }, [dataGetRequest]);

  useEffect(() => {
    if (dataCancelRequest) {
      clearGetRequest();
      setFireApiGetRequest(true);
      setFireApiCancelRequest(false);
    }
  }, [dataCancelRequest]);

  useEffect(() => {
    if (enable) {
      setUpdateFlag(enable);
    } else {
      setUpdateFlag(false);
    }
  }, [enable]);

  useEffect(() => {
    if (setTime) {
      setTime(formValue);
    }
  }, [dataCancelRequest]);

  useEffect(() => {
    if (diabledButton) {
      setFormValueExternal({
        ...externalFormValue,
        defaultAvailableDays:
          userWeekDay.filter((item: string) => item !== '') ?? [],
        defaultResourceTimeEnd: timeTimezoneToUTC(formatTime(timeEnd)) ?? '',
        defaultResourceTimeStart: timeTimezoneToUTC(formatTime(timeStart)) ?? ''
      });
    }
  }, [userWeekDay, timeEnd, timeStart]);

  useEffect(() => {
    if (dataSetRequest) {
      clearGetRequest();
      setFireApiGetRequest(true);
      setFireApiSetRequest(false);
      setError(false);
      if (type === 'myProfile') {
        clearTimesToActual();
      }
    }
  }, [dataSetRequest]);

  useEffect(() => {
    if (errorDataGetRequest || errorDataSetRequest || errorDataCancelRequest) {
      setFireApiGetRequest(false);
      setFireApiSetRequest(false);
      setFireApiCancelRequest(false);
      setError(true);
      if (errorDataSetRequest) {
        const { response } = errorDataSetRequest;
        if (response.status === 401) {
          setErrorMessage(response.statusText);
        }
      }
    }
  }, [errorDataGetRequest, errorDataSetRequest, errorDataCancelRequest]);

  useEffect(() => {
    clearTimesToActual();
  }, [profile]);

  // ------------------ JSX FUNCTIONS -------------------

  const getError = () =>
    !error ? (
      false
    ) : (
      <Container sx={{ mt: 1 }}>
        <Typography
          color="alert.main"
          sx={{ fontSize: 14 }}
          variant="primary-bold"
        >
          &bull; {`${errorMessage}`}
        </Typography>
      </Container>
    );

  const getDateField = (
    value: dayjs.Dayjs | null,
    setValue: React.Dispatch<React.SetStateAction<dayjs.Dayjs | null>>
  ) => {
    return isMobile ? (
      <MobileTimePicker
        disabled={!updateFlag}
        onChange={(newValue) => {
          setValue(newValue);
        }}
        renderInput={(params) => (
          <TimePickerFileld InputProps={{ style }} {...params} />
        )}
        value={value}
      />
    ) : (
      <DesktopTimePicker
        disabled={!updateFlag}
        onChange={(newValue) => {
          setValue(newValue);
        }}
        renderInput={(params) => (
          <TimePickerFileld InputProps={{ style }} {...params} />
        )}
        value={value}
      />
    );
  };

  return (
    <WhiteBoxContainer isMobile={isMobile} sx={{ marginTop: '24px' }}>
      <Box
        sx={{
          marginBottom: '8px',
          marginTop: '8px'
        }}
      >
        <FormLabel error={false} label={t('PROFILE.availableDays')} />
      </Box>
      {/* ------------ Checkbox Weekdays ------------ */}
      <Box sx={{ display: 'flex', width: isMobile ? '80%' : '100%' }}>
        {profile &&
          weekDays.map((ele: any, index) => (
            <Checkbox
              checked={userWeekDay.includes(ele.name)}
              disabled={!updateFlag}
              // eslint-disable-next-line react/no-array-index-key
              key={`${ele.name} - ${index}`}
              sx={{
                borderRadius: '50%',
                height: isMobile ? '40px' : '64px',
                marginTop: isMobile ? '8px' : '0px',
                width: isMobile ? '44px' : '64px'
              }}
              value={ele.name}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...label}
              checkedIcon={
                <DayCheckBoxSelected isMobile={isMobile}>
                  {ele.abr}
                </DayCheckBoxSelected>
              }
              icon={<DayCheckBox isMobile={isMobile}>{ele.abr}</DayCheckBox>}
              onChange={(value) =>
                handleChange(value.currentTarget.defaultValue)
              }
            />
          ))}
      </Box>
      {/* ------------ Start & End Time ------------ */}
      <GridBoxContainer isMobile={isMobile} sx={{ paddingX: '0px' }}>
        <>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <GridBox isMobile={isMobile}>
              <>
                <FormLabel
                  error={false}
                  label={t('PROFILE.availableTimeStart')}
                />
                <Box sx={{ marginTop: '8px' }}>
                  {getDateField(timeStart, setTimeStart)}
                </Box>
              </>
            </GridBox>
            <GridBox isMobile={isMobile}>
              <>
                <FormLabel
                  error={false}
                  label={t('PROFILE.availableTimeEnd')}
                />
                <Box sx={{ marginTop: '8px' }}>
                  {getDateField(timeEnd, setTimeEnd)}
                </Box>
              </>
            </GridBox>
          </LocalizationProvider>
          <GridBox isMobile={isMobile} sx={{ width: '100%' }}>
            {getError()}
            {requestMsg && diabledButton !== true ? (
              <>
                <Box>
                  <Typography
                    color="mainText.300"
                    sx={{ marginTop: '-10px', overflowWrap: 'break-word' }}
                    variant="body2"
                  >
                    {t('PROFILE.requestMessage')}
                    <Typography
                      color="primary.main"
                      onClick={handleClick}
                      sx={{
                        cursor: 'pointer',
                        marginLeft: '10px',
                        textDecoration: 'underline'
                      }}
                      variant="body_200"
                    >
                      {t('PROFILE.requestPop')}
                    </Typography>{' '}
                  </Typography>
                </Box>
                {/* Popover with Request Info */}
                <Popover
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    horizontal: 'center',
                    vertical: 'bottom'
                  }}
                  id={id}
                  onClose={handleClose}
                  open={open}
                  sx={{ margin: '10px' }}
                >
                  <Box sx={{ padding: '20px' }}>
                    <Typography
                      color="primary.main"
                      sx={{ display: 'flex' }}
                      variant="body2"
                    >
                      {t('PROFILE.timeStart')}
                      <Typography
                        color="primary.500"
                        sx={{ marginLeft: '5px' }}
                        variant="body2"
                      >
                        {format12Hours(requestValue?.requestedTimeStart)}
                      </Typography>
                    </Typography>
                    <Typography
                      color="primary.main"
                      sx={{ display: 'flex' }}
                      variant="body2"
                    >
                      {t('PROFILE.timeEnd')}
                      <Typography
                        color="primary.500"
                        sx={{ marginLeft: '5px' }}
                        variant="body2"
                      >
                        {format12Hours(requestValue?.requestedTimeEnd)}
                      </Typography>
                    </Typography>
                    <Typography
                      color="primary.main"
                      sx={{ display: 'flex' }}
                      variant="body2"
                    >
                      {t('PROFILE.days')}
                      <Typography
                        color="primary.500"
                        sx={{ marginLeft: '5px' }}
                        variant="body2"
                      >
                        {requestValue?.requestedAvailableDays?.toString()}
                      </Typography>
                    </Typography>
                  </Box>
                </Popover>
              </>
            ) : null}
          </GridBox>
          {/* ------------ Buttons section ----------- */}
          {diabledButton ? null : (
            <Box
              sx={{
                display: 'flex',
                justifyContent: updateFlag ? '' : 'flex-end',
                marginTop: isMobile ? '-16px' : 'auto',
                paddingX: isMobile ? '20px' : '16px',
                paddingY: isMobile ? '0px' : '8px'
              }}
            >
              {requestMsg ? (
                <Button
                  onClick={() => setFireApiCancelRequest(true)}
                  sx={{ width: '100%' }}
                  variant="destructive"
                >
                  {t('PROFILE.cancelRequest')}
                </Button>
              ) : (
                <ButtonFormGroup
                  actionButtonMsg={t('PROFILE.saveInformation')}
                  cancelAction={() => setUpdateFlag(false)}
                  enableEditAction={() => setUpdateFlag(true)}
                  enableEditButtonMsg={t('PROFILE.editInformation')}
                  isMobile={isMobile}
                  mainAction={() => {
                    handleSubmit();
                    setUpdateFlag(false);
                  }}
                  updateFlag={updateFlag}
                />
              )}
            </Box>
          )}
        </>
      </GridBoxContainer>
    </WhiteBoxContainer>
  );
};

export default AvailableDaysForm;
