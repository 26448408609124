import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import { Box, SliderProps, styled } from '@mui/material';
import { isMobile } from 'react-device-detect';
import api from '../api';
import { MainContainer } from './landing/Landing';
import logo from '../assets/images/Bluberry_Logo.png';
import useAuthStore from '../store/zustand/auth';
import { priorityRoleLogin } from '../helpers/roles';
import { loginLandingByRole } from '../helpers/routesConstants';

const getProportion = (baseSize: number, proportion: number) =>
  isMobile ? baseSize * proportion : baseSize;

const AuthProcessContainer = styled(Box)<SliderProps>(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.mainText?.['100'],
  borderRadius: theme.spacing(1),
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  display: 'flex',
  flexDirection: 'column',
  marginTop: isMobile ? theme.spacing(-25) : theme.spacing(-18),
  minHeight: 277,
  paddingTop: isMobile ? theme.spacing(1) : theme.spacing(3),
  width: getProportion(500, 0.61)
}));

function AdminProcess() {
  const navigate = useNavigate();
  const userState = useAuthStore((state) => state);
  const userRoles = [...userState.roles];
  const [checkCompany, setCheckCompany] = useState<boolean>(true);

  const { data } = useSWR(
    checkCompany ? 'api/currentCompany' : null,
    api.CompanyApi.getCurrentCompany
  );

  useEffect(() => {
    if (data) {
      setCheckCompany(false);
      const rolePriority = priorityRoleLogin(userRoles);
      if (data.onboardingCompleted) {
        if (rolePriority === 'Administrator') {
          navigate('/app/landing/');
        } else {
          navigate(
            loginLandingByRole[rolePriority as keyof typeof loginLandingByRole],
            {
              replace: true
            }
          );
        }
      } else {
        navigate('/app/company-onboarding');
      }
    }
  }, [data]);

  return (
    <MainContainer>
      <img
        alt="logo"
        className="logo-image"
        src={logo}
        style={{ width: 200 }}
      />
    </MainContainer>
  );
}

export default AdminProcess;
