import {
  SearchApi,
  SearchApiSearchGetAllRequest,
  SearchResponseDTO
} from '../api-client/generated';

const apiCaller = new SearchApi();

const getSearch = async (q: SearchApiSearchGetAllRequest) => {
  const response: any = await apiCaller.searchGetAll(q);
  const { data } = response;
  return data as SearchResponseDTO;
};

export default {
  getSearch
};
