import {
  CreateProposalMessagesTemplateDTO,
  ProposalMessagesTemplateApi,
  UpdateProposalMessagesTemplateDTO
} from '../api-client/generated/api';

const apiCaller = new ProposalMessagesTemplateApi();

interface Pagination {
  items: number;
  page: number;
}

const getAllProposalMessages = async (): Promise<any> => {
  const { data }: any =
    await apiCaller.proposalMessagesTemplateGetMessageTemplate();

  const auxData = data.map((item: any) => {
    return {
      ...item,
      description: item.message,
      name: item.title
    };
  });

  return auxData;
};

const getProposalMessages = async (params: Pagination): Promise<any> => {
  const { data } =
    await apiCaller.proposalMessagesTemplateGetMessagesTemplatesPaginate(
      params
    );

  return data;
};

const getProposalMessage = async (params: any) => {
  if (!params.title) {
    return null;
  }

  const { status, statusText } =
    await apiCaller.proposalMessagesTemplateGetMessageTemplate({
      title: params.title
    });

  return { status, statusText };
};

const getProposalMessageById = async (params: any) => {
  if (!params.id) {
    return null;
  }

  const response =
    await apiCaller.proposalMessagesTemplateGetProposalMessageTemplateById({
      id: params.id
    });

  const { data } = response;

  return data;
};

const createProposalMessage = async (params: any) => {
  if (!params?.name || !params?.description) {
    return null;
  }

  const createProposalMessagesTemplateDTO = {
    message: params?.description,
    title: params?.name
  } as CreateProposalMessagesTemplateDTO;

  const response =
    await apiCaller.proposalMessagesTemplateCreateProposalTemplate({
      createProposalMessagesTemplateDTO
    });

  const { data } = response;

  return data;
};

const deleteProposalMessage = async (params: any) => {
  if (!params.id) {
    return null;
  }

  const { status, statusText } =
    await apiCaller.proposalMessagesTemplateDeleteProposalTemplate({
      id: params.id
    });

  return { status, statusText };
};

const updateProposalMessage = async (params: any) => {
  if (!params.id || !params?.title || !params?.message) {
    return null;
  }

  const updateProposalMessagesTemplateDTO = {
    message: params?.message,
    title: params?.title
  } as UpdateProposalMessagesTemplateDTO;

  const result = await apiCaller.proposalMessagesTemplateUpdate({
    id: params.id,
    updateProposalMessagesTemplateDTO
  });
  const { status, statusText } = result;
  return { status, statusText };
};

export default {
  createProposalMessage,
  deleteProposalMessage,
  getAllProposalMessages,
  getProposalMessage,
  getProposalMessageById,
  getProposalMessages,
  updateProposalMessage
};
