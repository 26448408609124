/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line react/no-unstable-nested-components
import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  Typography,
  useTheme,
  Avatar,
  FormControl,
  Tooltip,
  Modal,
  FormControlLabel
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import useSWR, { useSWRConfig } from 'swr';
import {
  CloudCircleOutlined,
  CloudSync,
  CloudSyncOutlined
} from '@mui/icons-material';
import {
  textColors,
  secondaryColors,
  themePalette
} from '../../theme/themeConfig';
import { HeaderFormContainer, TitleFormBox } from '../../components/form/parts';
import Api from '../../api';
import { ReactComponent as UserIcons } from '../../assets/icons/user-circle.svg';
import { ReactComponent as LocationIcons } from '../../assets/icons/location-point-icons.svg';
import { ReactComponent as PhoneIcons } from '../../assets/icons/phone-icons.svg';
import { ReactComponent as Refresh } from '../../assets/icons/refresh.svg';
import StepDetailProjects from './StepDetailProjects';
import { statusStyle, resourseColors } from '../../utils/general';
import EditButton from '../../components/list/EditButton';
import RegularInput from '../../components/input/RegularInput';
import { IOSSwitch } from '../../components/companySettings/parts';
import ToastNotification from '../../components/shared/ToastNotification';
import { toastNotification } from '../../utils/notificationHelper';
import ConfirmModal from '../../components/modals/ConfirmModal';

interface Props {
  // eslint-disable-next-line react/require-default-props
  isClient?: boolean;
}

const DetailProject: React.FC<Props> = function DetailProject({
  isClient = false
}) {
  const [detailProject, setDetailProject] = useState<any>({});
  const [detailProjectEdit, setDetailProjectEdit] = useState<any>({});
  const [editFlag, setEditFlag] = useState<boolean>();
  const [apiEdit, setApiEdit] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [fireDelete, setFireDelete] = useState<boolean>(false);
  const [showOkModal, setShowOkModal] = useState<boolean>(false);
  const [deleteItem, setDelete] = useState<string | null>(null);
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state: params } = useLocation();
  const getProjectByIdAction = isClient
    ? Api.ClientPageApi.getProjectClientById
    : Api.ProjectApi.getProjectById;
  const type = params?.type;
  const dayChecked = params?.dayChecked;
  const utcUpadtedAt = params?.utcUpadtedAt;
  const { mutate: projectEnforceGeofencingMutate } = useSWRConfig();
  const [toastNotification, setToastNotification] =
    useState<toastNotification>();

  const {
    data: responseData,
    error: responseError,
    mutate
  } = useSWR(
    location?.state?.id || reload ? location?.state?.id : null,
    getProjectByIdAction
  );

  useEffect(() => {
    if (reload) {
      mutate(null, true);
    }
  }, [reload, mutate]);

  const { data: editNamResponse, error: editNameError } = useSWR(
    apiEdit ? { ...detailProjectEdit, url: 'edit-project/name' } : null,
    Api.ProjectApi.changeProjectName
  );

  const { data: cancelProjectResponse, error: cancelProjectError } = useSWR(
    fireDelete ? { ...detailProject, url: 'edit-project/name' } : null,
    Api.ProjectApi.cancelProject
  );

  useEffect(() => {
    if (cancelProjectResponse && !cancelProjectError) {
      setDetailProject({
        ...detailProject,
        status: 'Cancelled'
      });
      setShowOkModal(true);
    }
  }, [cancelProjectResponse]);

  useEffect(() => {
    if (showOkModal) {
      setShowDeleteModal(false);
      setShowOkModal(false);
    }
  }, [showOkModal]);

  useEffect(() => {
    if (responseData && !responseError) {
      setReload(false);
      const auxServiceTimes = responseData?.proposal?.lead?.serviceTimes.map(
        (serviceTime: any) => {
          const auxStartTime = dayjs()
            .hour(parseFloat(serviceTime?.startTime?.split(':')[0]))
            .minute(parseFloat(serviceTime?.startTime?.split(':')[1]))
            .add(
              Number(responseData?.proposal?.lead.address?.timezone || 0),
              'minute'
            );

          const auxEndTime = dayjs()
            .hour(parseFloat(serviceTime?.endTime?.split(':')[0]))
            .minute(parseFloat(serviceTime?.endTime?.split(':')[1]))
            .add(
              Number(responseData?.proposal?.lead.address?.timezone || 0),
              'minute'
            );

          return {
            ...serviceTime,
            formatedEndTime: auxEndTime.format('hh:mm A'),
            formatedStartTime: auxStartTime.format('hh:mm A'),
            timezone: responseData?.proposal?.lead.address?.timezone
          };
        }
      );

      const auxResponse = {
        ...responseData,
        id: responseData?.id,
        proposal: {
          ...responseData?.proposal,
          lead: {
            ...responseData?.proposal?.lead,
            serviceTimes: auxServiceTimes
          }
        }
      };

      setDetailProject(auxResponse);
    }
  }, [responseData]);

  const handleRedirect = () => {
    if (params?.lastPage) {
      navigate(params.lastPage, { state: params });
    } else if (isClient) {
      navigate('/client-page/projects/list');
    } else {
      navigate('/app/projects/list');
    }
  };

  useEffect(() => {
    if (editNamResponse) {
      setApiEdit(false);
      mutate(null, true);
    }
  }, [editNamResponse]);

  useEffect(() => {
    if (editNameError) {
      setApiEdit(false);
    }
  }, [editNameError]);

  const header = () => {
    return (
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        mt={2}
        spacing={1}
      >
        <Grid item md="auto" xs={12}>
          <Typography
            style={{
              ...statusStyle(detailProject?.status),
              borderRadius: '24px',
              display: 'inline-block',
              fontSize: '14px',
              marginLeft: '11px',
              marginTop: '13px',
              paddingInline: '21px',
              textAlign: 'center'
            }}
            variant="primary"
          >
            {detailProject.status}
          </Typography>
        </Grid>
        {!isClient && (
          <Grid item md="auto" xs={12}>
            <Button
              color="error"
              disabled={
                detailProject?.status === 'Cancelled' ||
                detailProject?.status === 'Completed'
              }
              onClick={() => setShowDeleteModal(true)}
              sx={{
                color: 'white',
                display: 'block',
                fontSize: '14px',
                marginBottom: '-17px',
                marginRight: '15px',
                width: '160px'
              }}
              variant="contained"
            >
              <Typography style={{}} variant="primary">
                {t('GENERAL.cancelProject')}
              </Typography>
            </Button>
          </Grid>
        )}
        {/* <Grid item md="auto" xs={12}>
          <Typography
            style={{
              color: secondaryColors.SECONDARY,
              display: 'block',
              fontSize: '14px',
              marginBottom: '-17px',
              marginRight: '15px'
            }}
            variant="primary"
          >
            Change Status
          </Typography>
        </Grid> */}
      </Grid>
    );
  };

  function ProjectNameAndAddress() {
    return (
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginLeft: '11px'
        }}
      >
        <Box sx={{ width: '100%' }}>
          {detailProject?.proposal?.lead?.client && (
            <div
              style={{
                color: secondaryColors.SECONDARY,
                display: 'flex',
                flexDirection: 'row',
                fontSize: '13px',
                paddingBottom: '8px'
              }}
            >
              <div
                style={{
                  fontSize: '13px',
                  height: '24px',
                  justifyContent: 'center',
                  marginRight: '12px',
                  width: '24px'
                }}
              >
                <UserIcons fill="grey" />
              </div>
              <Typography fontSize={13} style={{ marginTop: '5px' }}>
                {`${detailProject?.proposal?.lead?.client?.firstName} 
                  ${detailProject?.proposal?.lead?.client?.lastName}`}
              </Typography>
            </div>
          )}
          {detailProject?.proposal?.lead && (
            <div
              style={{
                color: secondaryColors.SECONDARY,
                display: 'flex',
                flexDirection: 'row',
                fontSize: '13px'
              }}
            >
              <div
                style={{
                  fontSize: '13px',
                  height: '24px',
                  justifyContent: 'center',
                  marginRight: '12px',
                  width: '24px'
                }}
              >
                <LocationIcons fill="grey" />
              </div>
              <Typography fontSize={13} style={{ marginTop: '4px' }}>
                {`${detailProject?.proposal?.lead?.address?.address}`}
              </Typography>
            </div>
          )}
          {detailProject?.proposal?.lead?.client && (
            <div
              style={{
                color: secondaryColors.SECONDARY,
                display: 'flex',
                flexDirection: 'row',
                fontSize: '13px',
                paddingBottom: '2px'
              }}
            >
              <div
                style={{
                  fontSize: '13px',
                  height: '24px',
                  justifyContent: 'center',
                  marginRight: '12px',
                  width: '24px'
                }}
              >
                <PhoneIcons fill="grey" />
              </div>
              <Typography fontSize={13} style={{ marginTop: '6px' }}>
                {`${detailProject?.proposal?.lead?.client?.phone}`}
              </Typography>
            </div>
          )}
        </Box>
      </Box>
    );
  }

  function DescriptionName() {
    return (
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginLeft: '11px'
        }}
      >
        <Typography
          style={{
            color: secondaryColors.SECONDARY_5,
            fontSize: '13px',
            marginBottom: '-17px'
          }}
          variant="secondary"
        >
          {`${t('LEAD.CREATE.GENERAL_INFORMATION.projectName')}:`}
          {!isClient &&
            detailProject?.status !== 'Cancelled' &&
            detailProject?.status !== 'Completed' && (
              <Button
                onClick={() => {
                  setEditFlag(!editFlag);
                  if (editFlag) {
                    setApiEdit(true);
                  } else {
                    setDetailProjectEdit(detailProject);
                  }
                }}
                variant="text"
              >
                <Typography color="secondary.main" variant="primary-bold">
                  <i
                    className={`custom-icon icon-${
                      !editFlag ? 'edit' : 'save'
                    }`}
                    style={{ fontSize: 24 }}
                  />
                </Typography>
              </Button>
            )}
        </Typography>
        <br />
        {detailProject?.proposal?.lead && (
          <div style={{ color: secondaryColors.SECONDARY, fontSize: '13px' }}>
            {!editFlag && `${detailProject?.proposal?.lead?.name}`}
            {editFlag && (
              <RegularInput
                customStyle={{ MarginRight: 3, width: '100%' }}
                onChange={(value) => {
                  setDetailProjectEdit({
                    ...detailProjectEdit,
                    proposal: {
                      ...detailProjectEdit?.proposal,
                      lead: {
                        ...detailProjectEdit?.proposal?.lead,
                        name: value
                      }
                    }
                  });
                }}
                value={detailProjectEdit?.proposal?.lead?.name}
              />
            )}
          </div>
        )}
      </Box>
    );
  }

  function DescriptionProject() {
    return (
      <Grid container md={12} spacing={1} xl={12} xs={12}>
        <Grid md={12} xl={12} xs={12}>
          <Typography
            style={{
              color: secondaryColors.SECONDARY_5,
              fontSize: '13px',
              // paddingBottom: '21px',
              textAlign: 'center'
            }}
            variant="primary-bold"
          >
            {detailProject?.proposal?.lead?.projectType === 'Regular' &&
              detailProject?.proposal?.lead?.projectStartDate && (
                <Box style={{ paddingTop: '7px' }}>
                  {`${dayjs(
                    detailProject.proposal.lead?.projectStartDate
                  ).format('DD MMMM, YYYY')}`}

                  {detailProject?.proposal?.lead?.projectEndDate && (
                    <span>
                      {' - '}
                      {`${dayjs(
                        detailProject?.proposal?.lead?.projectEndDate
                      ).format('DD MMMM, YYYY')}`}
                    </span>
                  )}
                </Box>
              )}

            {detailProject?.proposal?.lead?.projectType === 'Ad-Hoc' &&
              detailProject?.proposal?.lead?.projectStartDate && (
                <Box>
                  {`${dayjs(
                    detailProject.proposal.lead.projectStartDate
                  ).format('DD MMMM, YYYY')}`}
                </Box>
              )}
          </Typography>
        </Grid>
      </Grid>
    );
  }

  function DescriptionType() {
    return (
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginBottom: '0px',
          marginLeft: '11px'
        }}
      >
        {detailProject?.proposal?.lead && (
          <Box alignItems="center" display="flex">
            <Typography
              style={{ color: secondaryColors.SECONDARY_5, fontSize: '13px' }}
              variant="primary-bold"
            >
              {`${t('LISTING_PAGES.typeofProjtect')}:`}
            </Typography>
            <Typography
              style={{
                color: secondaryColors.SECONDARY,
                fontSize: '13px',
                marginBottom: '-4px',
                marginLeft: '11px'
              }}
            >{`${detailProject?.proposal?.lead?.projectType}`}</Typography>
          </Box>
        )}
      </Box>
    );
  }

  function ProjectSupervisor() {
    return (
      <Box display="flex" style={{ marginLeft: '11px' }}>
        <Box display="flex" width="30%">
          <Box width={160}>
            <Typography
              style={{ color: secondaryColors.SECONDARY_5, fontSize: '13px' }}
              variant="primary-bold"
            >
              {`${t('GENERAL.supervisor')}:`}
              <Box display="flex">
                {detailProject?.proposal?.lead?.supervisors?.map(
                  (supervisor: any, index: any) => {
                    const initials = supervisor?.name
                      ?.split(' ')
                      ?.slice(0, 2)
                      ?.map((word: string) => word[0]?.toUpperCase())
                      ?.join('');
                    const auxKey = `detail-project-supervisor-${supervisor?.id}`;
                    return (
                      <Box
                        key={auxKey}
                        sx={{
                          fontSize: '13px',
                          marginTop: '-2px',
                          padding: '8px'
                        }}
                      >
                        {initials && (
                          <Tooltip arrow title={supervisor?.name}>
                            <Avatar
                              alt={supervisor?.name}
                              sx={{
                                backgroundColor: resourseColors[index],
                                cursor: 'pointer',
                                marginRight: '-20px'
                              }}
                            >
                              {supervisor?.profilePic ? null : initials}
                            </Avatar>
                          </Tooltip>
                        )}
                      </Box>
                    );
                  }
                )}
              </Box>
            </Typography>
          </Box>
          <FormControl />
        </Box>
        <Box display="flex" mt={2} width="30%" />
      </Box>
    );
  }

  function ProjectTeams() {
    // Filtrar los técnicos únicos
    const uniqueTechnicians =
      detailProject?.proposal?.lead?.technicians?.filter(
        (technician: any, index: any, self: any) =>
          index === self.findIndex((t: any) => t.id === technician.id)
      );

    return (
      <Box display="flex" style={{ marginLeft: '11px' }}>
        <Box display="flex" flexDirection="column" width="30%">
          <Box width={160}>
            <Typography
              style={{ color: secondaryColors.SECONDARY_5, fontSize: '13px' }}
              variant="primary-bold"
            >
              {`${t('GENERAL.team')}:`}
              <Box display="flex">
                {/* Utilizar el array de técnicos filtrados */}
                {uniqueTechnicians?.map((technician: any, index: any) => {
                  const initials = technician?.name
                    ?.split(' ')
                    ?.slice(0, 2)
                    ?.map((word: string) => word[0]?.toUpperCase())
                    ?.join('');
                  const auxKey = `detail-project-team-${technician?.id}`;
                  return (
                    <Box
                      key={auxKey}
                      sx={{
                        marginTop: '-2px',
                        padding: '8px'
                      }}
                    >
                      {initials && (
                        <Tooltip arrow title={technician?.name}>
                          <Avatar
                            alt={technician.name}
                            sx={{
                              backgroundColor: resourseColors[index],
                              cursor: 'pointer',
                              marginRight: '-20px'
                            }}
                          >
                            {technician?.profilePic ? null : initials}
                          </Avatar>
                        </Tooltip>
                      )}
                    </Box>
                  );
                })}
              </Box>
            </Typography>
          </Box>
          <FormControl />
        </Box>
        <Box display="flex" mt={2} width="30%" />
      </Box>
    );
  }

  function EnforceGeofencing() {
    return (
      <Box display="flex" style={{ marginLeft: '11px' }}>
        {detailProject?.status !== 'Cancelled' &&
          detailProject?.status !== 'Completed' && (
            <Box display="flex" flexDirection="column" width="30%">
              <Box width={160}>
                <Typography
                  style={{
                    color: secondaryColors.SECONDARY_5,
                    fontSize: '13px'
                  }}
                  variant="primary-bold"
                >
                  {`${t('COMPANY_SETTINGS.enforceGeofencing')}:`}
                  <Box>
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          checked={detailProject?.enforceGeofencing}
                          sx={{ m: 1 }}
                        />
                      }
                      disabled={false}
                      label=""
                      onChange={() => {
                        setDetailProject({
                          ...detailProject,
                          enforceGeofencing: !detailProject.enforceGeofencing
                        });
                        setToastNotification({
                          text: t('NOTIFICATION.TOAST.projectUpdatedSuccess'),
                          type: 'success'
                        });
                        projectEnforceGeofencingMutate(
                          '/project/enforceGeofencing',
                          Api.ProjectApi.patchChangeProjectEnforceGeofencing({
                            enforceGeofencing: !detailProject.enforceGeofencing,
                            id: detailProject.id
                          })
                        );
                      }}
                    />
                  </Box>
                </Typography>
              </Box>
              <FormControl />
            </Box>
          )}
        <Box display="flex" mt={2} width="30%" />
      </Box>
    );
  }
  // se comentar por que no se ha integrado nada con chatss
  // function Buttons() {
  //   return (
  //     <Grid
  //       container
  //       justifyContent="center"
  //       md={12}
  //       spacing={1}
  //       xl={11}
  //       xs={12}
  //     >
  //       <Grid item md={10} xl={10} xs={12}>
  //         <Button
  //           fullWidth
  //           onClick={() => {}}
  //           sx={{
  //             alignItems: 'center',
  //             display: 'flex',
  //             fontSize: '11px',
  //             justifyContent: 'space-between'
  //           }}
  //           variant="primary"
  //         >
  //           <span style={{ margin: 'auto' }}>
  //             {t('GENERAL.startConvertation')}
  //           </span>
  //           <SendIcons fill="none" />
  //         </Button>
  //       </Grid>
  //     </Grid>
  //   );
  // }

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', gap: 3, paddingTop: 2 }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
          gap: 2,
          justifyContent: 'left'
        }}
      >
        <Box
          sx={{
            alignContent: 'center',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            paddingLeft: 4
          }}
        >
          <Typography
            sx={{
              alignItems: 'center',
              display: 'flex',
              fontSize: '28px',
              fontWeight: '900',
              gap: 2,
              justifyContent: 'center'
            }}
            variant="primary-bold"
          >
            <ArrowBackIcon fill="grey" onClick={() => handleRedirect()} />
            {t('MENU.projects')}
          </Typography>
        </Box>
        <Button
          onClick={() => {
            setReload(true);
            setDetailProject({});
          }}
          variant="text"
        >
          <Typography
            sx={{ display: 'flex', gap: 1, justifyContent: 'center' }}
            variant="body_100"
          >
            <CloudSyncOutlined fontSize="small" />

            {t('GENERAL.sync')}
          </Typography>
        </Button>
      </Box>
      <Grid
        container
        pl={6}
        spacing={2}
        style={{ backgroundColor: `${themePalette.BACKGROUND}` }}
      >
        {detailProject?.proposal?.lead && (
          <Grid
            style={{
              backgroundColor: 'white',
              marginBottom: '16px',
              paddingLeft: '12px',
              paddingRight: 12
            }}
            xs={3}
          >
            {toastNotification && (
              <ToastNotification
                onClose={() => setToastNotification(undefined)}
                text={toastNotification.text}
                type={toastNotification.type}
              />
            )}
            {header()}
            <br />
            {ProjectNameAndAddress()}
            <hr style={{ borderColor: 'rgba(247, 246, 249, 0.2)' }} />
            {DescriptionName()}
            <hr style={{ borderColor: 'rgba(247, 246, 249, 0.2)' }} />
            {DescriptionProject()}
            <hr style={{ borderColor: 'rgba(247, 246, 249, 0.2)' }} />
            {DescriptionType()}
            {!isClient && (
              <hr style={{ borderColor: 'rgba(247, 246, 249, 0.2)' }} />
            )}
            {!isClient && ProjectSupervisor()}
            {!isClient && ProjectTeams()}
            {!isClient && EnforceGeofencing()}
            {!isClient && <br />}
            {/* {Buttons()} */}
            <br />
          </Grid>
        )}
        <Grid item xs={9}>
          <StepDetailProjects
            dayChecked={dayChecked}
            detailProject={detailProject}
            isClient={isClient}
            projectType={detailProject?.proposal?.lead?.projectType}
            refresh={() => {
              setReload(true);
            }}
            typeView={type}
            utcUpadtedAt={utcUpadtedAt}
          />
        </Grid>
      </Grid>
      {showDeleteModal && (
        <ConfirmModal
          modalText={t('MODAL.confirmCancelProject')}
          modalType="project"
          setSentFlag={setFireDelete}
          setShowModal={setShowDeleteModal}
          setShowOkModal={setShowOkModal}
        />
      )}
    </Box>
  );
};

export default DetailProject;
