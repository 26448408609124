import { LocationHistoryApi } from '../api-client/generated';
import { getUserLocation } from '../utils/geofencingHelper';

const apiCaller = new LocationHistoryApi();

const getLocationHistory = async (userId: string, date: string) => {
  const { data } = await apiCaller.locationHistoryFind({
    userId,
    date
  });
  return data;
};

const createLocationHistory = async (params: any) => {
  const { lat, long } = await getUserLocation();
  const { data } = await apiCaller.locationHistoryCreate({
    locationHistoryCreationDTO: {
      latitude: lat,
      longitude: long,
      action: params.action,
      calendarEventId: params.calendarEventId,
      projectId: params.projectId,
      taskIterationId: params.taskIterationId
    }
  });
  return data;
};

export default {
  getLocationHistory,
  createLocationHistory
};
