import { GeofencingMeasureEnum } from "../api-client/generated";

export const getUserLocation: any = async () => {
    return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const lat = position.coords.latitude;
                    const long = position.coords.longitude;
                    resolve({ lat, long });
                },
                (error) => {
                    reject();

                }
            );
        } else {
            reject(new Error("Geolocation is not supported by this browser."));
        }
    });
};

export function deg2rad(deg: any) {
    return deg * (Math.PI / 180);
}

export function getDistanceFromLatLonInKm(lat1: any, lon1: any, lat2: any, lon2: any) {
    const R = 6371; // Radius in km
    const dLat = deg2rad(lat2 - lat1); // deg2rad below
    const dLon = deg2rad(lon2 - lon1);
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // Distance in km
    return d;
}

/**
 * Convert distance between kilometers and miles.
 * 
 * @param {number} value - The distance value to convert.
 * @param {string} unit - The unit of the value ('km' for kilometers, 'mi' for miles).
 * @returns {number} - The converted distance value.
 */
export function convertDistance(value: any, unit: any) {
    const KM_TO_MI = 0.621371; // Conversion factor from kilometers to miles
    const MI_TO_KM = 1 / KM_TO_MI; // Conversion factor from miles to kilometers
    
    const result = unit === GeofencingMeasureEnum.Kilometer ? value * KM_TO_MI : value * MI_TO_KM;
    return result.toFixed(2);
}

export function checkGeofence(lat: any, long: any, geofenceCenter: {
    lat: number;
    long: number;
    measureType: string;
    radiusDistance: number;
}) {

    // Calculate the distance in kilometers
    const distanceInKm = getDistanceFromLatLonInKm(lat, long, geofenceCenter.lat, geofenceCenter.long);

    // Convert distance to the desired measureType
    const distance = geofenceCenter.measureType === GeofencingMeasureEnum.Miles
        ? convertDistance(distanceInKm, GeofencingMeasureEnum.Kilometer)
        : distanceInKm;

    // Convert radiusDistance to kilometers if necessary
    const radiusInKm = geofenceCenter.measureType === GeofencingMeasureEnum.Miles
        ? convertDistance(geofenceCenter.radiusDistance, GeofencingMeasureEnum.Miles)
        : geofenceCenter.radiusDistance;

    // Check if the distance is within the geofence radius
    return distance <= radiusInKm;
}