import {
  InvoiceManagementApi,
  InvoiceManagementApiInvoiceGetInvoiceListRequest,
  InvoiceListResponseDTO,
  InvoiceManagementApiInvoiceGetInvoiceByIdRequest,
  InvoiceManagementApiInvoiceResendInvoiceRequest,
  InvoiceManagementApiInvoiceUpdateInvoiceStatusRequest,
  UpdateInvoiceStatusDTOStatusEnum
} from '../api-client/generated';

const apiCaller = new InvoiceManagementApi();

const getInvoicesList = async (
  projectId: string,
  status: 'PENDING' | 'OVERDUE' | 'PAID' | undefined
): Promise<Array<InvoiceListResponseDTO>> => {
  try {
    const requestParams: InvoiceManagementApiInvoiceGetInvoiceListRequest = {
      projectId,
      ...(status && { status })
    };

    const response = await apiCaller.invoiceGetInvoiceList(requestParams);

    const { data } = response;

    return data;
  } catch (error) {
    console.error('Error fetching invoices:', error);
    return [];
  }
};

const getInvoicesById = async (id: string) => {
  if (!id) {
    return null;
  }
  const requestParams: InvoiceManagementApiInvoiceGetInvoiceByIdRequest = {
    id
  };

  const response = await apiCaller.invoiceGetInvoiceById(requestParams);
  const { data } = response;
  return data;
};

const getStatusEnum = (
  status: string
): UpdateInvoiceStatusDTOStatusEnum | undefined => {
  switch (status.toLowerCase()) {
    case 'paid':
      return UpdateInvoiceStatusDTOStatusEnum.Paid;
    case 'pending':
      return UpdateInvoiceStatusDTOStatusEnum.Pending;
    case 'overdue':
      return UpdateInvoiceStatusDTOStatusEnum.Overdue;
    default:
      return undefined;
  }
};

const updateInvoiceByStatus = async (id: string, status: string) => {
  if (!id || !status) {
    return null;
  }

  const apiCaller = new InvoiceManagementApi();
  const statusEnum = getStatusEnum(status);
  if (!statusEnum) {
    throw new Error(`status no valid: ${status}`);
  }

  const requestParams: InvoiceManagementApiInvoiceUpdateInvoiceStatusRequest = {
    id,
    updateInvoiceStatusDTO: { status: statusEnum }
  };
  const response = await apiCaller.invoiceUpdateInvoiceStatus(requestParams);
  return response.status === 200 ? id : null;
};

const resendInvoiceById = async (id: string) => {
  if (!id) {
    return null;
  }

  const requestParams: InvoiceManagementApiInvoiceResendInvoiceRequest = {
    id
  };

  const response = await apiCaller.invoiceResendInvoice(requestParams);
  const { data } = response;
  return data;
};

const getInvoiceByToken = async (tokenParam: any) => {
  const requestParams = {
    token: tokenParam.token
  };
  const response = await apiCaller.invoiceGetInvoiceByToken(requestParams);
  const { data } = response;
  return data;
};

const getClientInvoicesFiltered = async (params: any) => {
  const { id: clientId, month: m, year } = params;

  const month = m === 0 ? undefined : m;
  const response = await apiCaller.invoiceGetinvoicesFilteredofClientPerProject(
    {
      clientId,
      month: month < 10 ? `0${month}` : month,
      year
    }
  );
  const { data }: any = response;
  return data;
};

export default {
  getClientInvoicesFiltered,
  getInvoiceByToken,
  getInvoicesById,
  getInvoicesList,
  resendInvoiceById,
  updateInvoiceByStatus
};
