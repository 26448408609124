export const ROLES = {
  ADMINISTRATOR: 'Administrator',
  CLIENT: 'Client',
  FINANCE: 'Finance',
  OPERATIONS: 'Operations',
  SUPER_USER: 'SuperUser',
  SUPERVISOR: 'Supervisor',
  TECHNICIAN: 'Technician'
};

export default {
  ROLES
};

export const priorityRoleLogin = (
  roles: Array<String>,
  needAdmin: boolean = false
) => {
  if (needAdmin && roles.includes(ROLES.ADMINISTRATOR)) {
    return [ROLES.ADMINISTRATOR];
  }
  if (roles.includes(ROLES.TECHNICIAN)) {
    return [ROLES.TECHNICIAN];
  }
  if (roles.includes(ROLES.OPERATIONS)) {
    return [ROLES.OPERATIONS];
  }
  return roles[0];
};
