/* eslint-disable no-unreachable */
/* eslint-disable max-len */
import dayjs from 'dayjs';
import {
  ClientsPortalApi,
  CalendarEventsProjectDetailResponseDTO,
  ClientsAuthApi,
  ClientsAuthApiClientAuthCompleteClientOnboardingRequest,
  GenerateScheduleDTO
} from '../api-client/generated';
import { formatTimesAndCalculateDifference } from '../utils/calendarHelper';

interface userAuth {
  email: string;
}

const sentEmailWithAuthLogin = async (params: userAuth) => {
  if (!params) {
    return null;
  }
  const { email } = params;
  const clientsAuthApi = new ClientsAuthApi();
  const response = await clientsAuthApi.clientAuthGetAppLink({ email });
  const { status } = response;
  return { status };
};

const getCompanies = async (params: { token: string }) => {
  const { token } = params;
  if (!token) {
    return null;
  }
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
  const clientsAuthApi = new ClientsAuthApi();
  const { data } = await clientsAuthApi.clientAuthGetClientCompanies(config);
  return data as unknown as any[];
};

const getTokenWithCompany = async (params: { id: string; token: string }) => {
  if (!params) {
    return null;
  }
  const { id, token } = params;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };
  const clientsAuthApi = new ClientsAuthApi();
  const { data } = await clientsAuthApi.clientAuthGetAppLinkWithCompany(
    { id },
    config
  );
  return data as unknown as any;
};

const getClientInformation = async () => {
  const clientsAuthApi = new ClientsPortalApi();
  const { data } = await clientsAuthApi.clientPortalGetAppLink();
  return data as unknown as any;
};

const onboardingClient = async (params: any) => {
  if (!params) {
    return null;
  }

  const {
    billingAddress,
    billingEmail,
    businessName,
    email,
    firstName,
    jobTitle,
    lastName,
    notes,
    phone,
    timeZone,
    addresses,
    id,
    contacts,
    workOrderEmails
  } = params;

  const dtoParams: ClientsAuthApiClientAuthCompleteClientOnboardingRequest = {
    completeClientOnboardingDTO: {
      billingAddress,
      billingEmail,
      businessName,
      firstName,
      jobTitle,
      lastName,
      notes,
      phone,
      workOrderEmails
    }
  };

  const apiCaller = new ClientsAuthApi();
  const response = await apiCaller.clientAuthCompleteClientOnboarding(
    dtoParams
  );
  const { data } = response;
  if (response.status === 201) {
    const apiPlaceCaller = new ClientsPortalApi();
    await apiPlaceCaller.clientPortalUpsertAddress({
      upsertAddressDTO: { addresses }
    });
    if (response.status === 201) {
      const apiContactCaller = new ClientsPortalApi();
      await apiContactCaller.clientPortalUpsert({
        upsertContactDTO: { contacts }
      });
    }
  }
  return data as unknown as any;
};

const getClientProjects = async () => {
  const clientsApi = new ClientsPortalApi();
  const response = await clientsApi.clientPortalGetProjectsOfClient();
  return response as unknown as any;
};

const getProjectById = async (id: string) => {
  if (!id) {
    return null;
  }

  const clientsAuthApi = new ClientsPortalApi();
  // const response = await clientsAuthApi.clientPortalGetProjectById();
  // return response as unknown as any;

  const response = await clientsAuthApi.clientPortalGetProjectById({ id });
  const { data } = response;
  return data;
};

function processScheduleResponse(response: any) {
  // Verificar si response es un array
  if (!Array.isArray(response)) {
    console.error('Response needs to be an array');
    return null;
  }

  // Convertir las fechas de formato UTC a formato D/M/YYYY para facilitar el manejo
  const processedResponse = response.map((item: any) => {
    const processedItem = {
      days: item?.days.map((utcDateString: string) => {
        const parts = utcDateString.split('/');
        const formattedDate = `${parts[1]}/${parts[0]}/${parts[2]}`;
        return formattedDate;
      }),

      id: item.id,
      range: item?.range,
      // range: item?.range
      //   ?.split(' - ')
      //   ?.map((date: string) => {
      //     return new Date(date).toLocaleDateString(); // Convertir cada fecha del rango a formato local
      //   })
      //   .join(' - '), // Unir las fechas del rango con el separador "-"
      stateDatesList: item?.stateDatesList,
      status: item.status
    };
    return processedItem;
  });
  return processedResponse;
}

const generateSchedule = async (
  startDate: string,
  endDate: string,
  frequency: string,
  frequencyWeek: string,
  frequencyDays: Array<string>,
  projectId: string,
  serviceTimeId: number
): Promise<
  | {
      days: any;
      id: any;
      range: any;
      stateDatesList: any;
      status: any;
    }[]
  | null
  | undefined
  // eslint-disable-next-line consistent-return
> => {
  const apiCaller = new ClientsPortalApi();
  const startDateUTC = new Date(startDate).toISOString();
  const endDateUTC = new Date(endDate).toISOString();
  // Crear un objeto con las propiedades correctas
  const generateScheduleDTO: GenerateScheduleDTO = {
    endDate: endDateUTC,
    frequency,
    frequencyDays,
    frequencyWeek,
    projectId,
    serviceTimeId,
    startDate: startDateUTC
  };
  if (generateScheduleDTO.projectId !== undefined) {
    const response = await apiCaller.clientPortalGenerateSchedule({
      generateScheduleDTO
    });
    // Extraer la propiedad 'data' del objeto de respuesta procesado
    const { data } = response;
    // Procesar la respuesta para convertir las fechas UTC a formato local
    const responseLocal = processScheduleResponse(data);
    return responseLocal;
  }
};

const getDetailByProject = async (
  projectId: string,
  serviceTimesId: number,
  currentDay: string,
  scheduledOn: any,
  daySelected: string
  // eslint-disable-next-line consistent-return
): Promise<CalendarEventsProjectDetailResponseDTO | undefined> => {
  if (!currentDay || currentDay.length === 0) {
    return undefined;
  }
  const apiCallerCalendar = new ClientsPortalApi();

  const parts = currentDay.split('/');

  let setDay;

  // Verificar si parts tiene la longitud esperada (3 elementos)
  if (parts.length === 3) {
    // Construir la fecha con los elementos de parts
    setDay = `${parts[2]}-${(parts[1] || '').padStart(2, '0')}-${(
      parts[0] || ''
    ).padStart(2, '0')}`;
  }

  const daySelectedParts = daySelected.split('/');

  let calendarEvent;
  if (daySelectedParts.length === 3) {
    // Construir la fecha con los elementos de daySelectedParts
    calendarEvent = `${daySelectedParts[2]}-${(
      daySelectedParts[1] || ''
    ).padStart(2, '0')}-${(daySelectedParts[0] || '').padStart(2, '0')}`;
  }

  // Verificar si setDay está definido antes de usarlo
  let currentDaytst;
  if (setDay) {
    currentDaytst = setDay.concat(
      'T',
      scheduledOn === 'Date' ? '00:00:00' : scheduledOn
    );
  }

  const currentDayUTC = dayjs(currentDaytst)
    .utc()
    .format('YYYY-MM-DD HH:mm:ss.SSS');

  const currentDate = new Date(currentDayUTC);

  // eslint-disable-next-line no-restricted-globals
  if (calendarEvent && !isNaN(currentDate.getTime())) {
    const response =
      await apiCallerCalendar.clientPortalFindByDateAndServiceTime({
        calendarEventDay: calendarEvent,
        date: currentDayUTC,
        projectId,
        serviceTimeId: serviceTimesId
      });
    const { data } = response;

    if (response.data?.serviceTime && response.data?.serviceTime?.services) {
      let serviceTimeHours = 0;
      const auxData = {
        ...data,
        serviceTime: {
          ...data.serviceTime,
          services: data.serviceTime.services.map((service: any) => {
            let serviceHours = 0;
            const auxTasks = service.tasks.map((task: any) => {
              let taskHours = 0;
              const auxTaskIte = task.taskIteration.map((taskIte: any) => {
                let checkListCompleted = false;

                if (
                  task?.checklist.length > 0 &&
                  taskIte?.checkList.length > 0 &&
                  taskIte.checkList.every((check: any) => check.checked)
                ) {
                  checkListCompleted = true;
                } else if (task?.checklist.length === 0) {
                  checkListCompleted = true;
                }

                if (taskIte?.status === 'created') {
                  return {
                    ...taskIte,
                    checkListCompleted,
                    workedTime: 0
                  };
                }

                if (taskIte?.startTime) {
                  const taskWorkedTimes = formatTimesAndCalculateDifference(
                    taskIte?.startTime,
                    taskIte?.endTime || new Date().toISOString()
                  );

                  taskHours += taskWorkedTimes.differenceInMinutes;

                  return {
                    ...taskIte,
                    checkListCompleted,
                    workedTime: taskWorkedTimes.differenceInMinutes
                  };
                }

                return {
                  ...taskIte,
                  workedTime: 0
                };
              });
              serviceHours += taskHours;

              return {
                ...task,
                taskIteration: auxTaskIte,
                workedTime: taskHours
              };
            });
            serviceTimeHours += serviceHours;

            return {
              ...service,
              tasks: auxTasks,
              workedTime: serviceHours
            };
          }),
          workedTime: serviceTimeHours
        }
      };

      return auxData;
    }

    return data;
  }
  // }
};

const getProjectClientById = async (id: string) => {
  if (!id) {
    return null;
  }
  const clientsApi = new ClientsPortalApi();
  const response: any = await clientsApi.clientPortalGetProjectById({ id });
  const { data } = response;
  return data;
};

export default {
  generateSchedule,
  getClientInformation,
  getClientProjects,
  getCompanies,
  getDetailByProject,
  getProjectById,
  getProjectClientById,
  getTokenWithCompany,
  onboardingClient,
  sentEmailWithAuthLogin
};
