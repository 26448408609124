/* eslint-disable react/jsx-props-no-spreading */
import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  Container,
  Modal,
  Paper,
  SliderProps,
  TextField,
  Typography,
  styled
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import useSWR from 'swr';
import { useTranslation } from 'react-i18next';
import api from '../../api';
import AutoCompleteInput from '../input/AutoCompleteInput';
import RegularInput from '../input/RegularInput';
import Loader from '../shared/Loader';
import ConfirmModal from './ConfirmModal';
import { toastNotification } from '../../utils/notificationHelper';

const SubTitle = styled(Typography)<SliderProps>(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '17px',
  fontWeight: 'bolder',
  marginBottom: '-12px'
}));

const style = {
  bgcolor: 'background.paper',
  borderRadius: '0.5em',
  boxShadow: '24',
  height: '70vh',
  left: '50%',
  padding: '40px 50px',
  position: 'absolute' as 'absolute',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900
};

interface Props {
  open: boolean;
  setClose: () => void;
  setToastNotification: (notification: toastNotification) => void;
  setValue: (item: any) => void;
}

const ProposalMessagesModal: React.FC<Props> = function ProposalMessagesModal({
  open,
  setValue,
  setToastNotification,
  setClose
}) {
  const { t } = useTranslation();
  const [getData, setData] = useState<boolean>(false);
  const [messages, setMessages] = useState<Array<any>>([]);
  const [messageSelected, setMessageSelected] = useState<any>();
  const [createMode, setCreateMode] = useState<boolean>(false);
  const [fireConfirmExit, setFireConfirmExit] = useState<boolean>(false);
  const [fireClose, setFireClose] = useState<boolean>(false);
  const [showOkModal, setShowOkModal] = useState<boolean>(false);
  const [auxManualClose, setAuxManualClose] = useState<boolean>(false);
  const [fireAddMessage, setFireAddMessage] = useState<boolean>(false);
  const [fireAPICreateMessage, setFireAPICreateMessage] =
    useState<boolean>(false);
  const [addToCatalog, setAddToCatalog] = useState<boolean>(false);

  //  ================================================================================= SWR

  const { data: messagesData, isValidating: dataLoading } = useSWR(
    getData ? { url: 'api/AllProposalMessages' } : null,
    api.ProposalMessagesTemplateApi.getAllProposalMessages
  );

  const {
    data: messageCreateData,
    error: errorMessageCreate,
    isValidating: dataCreateMessageLoading
  } = useSWR(
    fireAPICreateMessage && messageSelected?.message
      ? {
          description: messageSelected?.message,
          name: messageSelected?.title
        }
      : null,
    api.ProposalMessagesTemplateApi.createProposalMessage
  );

  //  ================================================================================= FUNCTIONS

  //  ================================================================================= USEEFFECTS

  useEffect(() => {
    if (messagesData) {
      setData(false);
      setMessages(messagesData);
    }
  }, [messagesData]);

  useEffect(() => {
    if (!dataCreateMessageLoading && !errorMessageCreate && messageCreateData) {
      setData(true);
      setFireAPICreateMessage(false);
      setValue({
        message: messageSelected?.message,
        messageTemplateId: String(Number(messageCreateData?.id)) || ''
      });
      setToastNotification({
        text: t('NOTIFICATION.TOAST.createSuccess', ['Message template']),
        type: 'success'
      });
      setClose();
      setFireAddMessage(false);
    }
  }, [messageCreateData]);

  useEffect(() => {
    if (errorMessageCreate) {
      const error =
        errorMessageCreate?.response?.data?.message ||
        t('NOTIFICATION.TOAST.createError', [' the message template']);
      setToastNotification({ text: error, type: 'error' });
      setClose();
      setFireAddMessage(false);
    }
  }, [errorMessageCreate]);

  useEffect(() => {
    setData(true);
  }, []);

  useEffect(() => {
    if (auxManualClose) {
      setAuxManualClose(false);
      if (createMode && messageSelected?.message) {
        setFireConfirmExit(true);
      } else {
        setClose();
      }
    }
  }, [auxManualClose]);

  useEffect(() => {
    if (fireClose) {
      setFireClose(false);
      setFireConfirmExit(false);
    }
  }, [fireClose]);

  useEffect(() => {
    if (showOkModal) {
      setFireClose(false);
      setClose();
    }
  }, [showOkModal]);

  useEffect(() => {
    if (fireAddMessage) {
      if (addToCatalog) {
        setFireAPICreateMessage(true);
      } else {
        setValue({
          message: messageSelected?.message,
          messageTemplateId: messageSelected?.id || ''
        });
        setClose();
        setFireAddMessage(false);
      }
    }
  }, [fireAddMessage]);

  useEffect(() => {
    if (messageSelected) {
      if (undefined !== messageSelected?.id) {
        // setAskService(true);
      } else {
        // setValue({ ...messageSelected });
      }
    }
  }, [messageSelected]);

  //  ================================================================================= RENDER

  return (
    <Container
      sx={{
        alignItems: 'normal',
        margin: '40px'
        // padding: '40px'
      }}
    >
      <Modal
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
        aria-describedby="modal-modal-description-profile"
        aria-labelledby="modal-modal-title-profile"
        onClose={() => setAuxManualClose(true)}
        open={open}
      >
        <Box
          sx={{
            ...style,
            height: 'auto',
            minWidth: '600px',
            width: 'auto'
          }}
        >
          <Box
            alignItems="start"
            display="flex"
            flexDirection="column"
            justifyContent="start"
          >
            <Box
              alignItems="center"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              width="100%"
            >
              <Typography mb={2} mt={3} variant="title">
                {t('LEAD.CREATE.PROPOSAL.buttonAddCatalogMessage')}
              </Typography>
            </Box>
            <SubTitle>
              {t('LEAD.CREATE.PROPOSAL.messageTempleateTitle')}
            </SubTitle>
            {dataLoading && <Loader customColor="primary" />}
            {!dataLoading && (
              <Box width="100%">
                <AutoCompleteInput
                  closeOnSelect
                  onChange={(event: any, newValue: any | null) => {
                    if (undefined !== newValue?.createValue) {
                      setMessageSelected({
                        id: null,
                        message: '',
                        title: newValue.createValue
                      });
                      setCreateMode(true);
                    } else {
                      setMessageSelected(newValue);
                      setCreateMode(false);
                    }
                  }}
                  options={messages}
                  renderInput={(params) => (
                    <TextField
                      placeholder={`${t(
                        'LEAD.CREATE.PROPOSAL.autoCompletePlaceholder'
                      )}`}
                      sx={{
                        '.MuiOutlinedInput-notchedOutline': {
                          borderStyle: 'hidden'
                        },
                        borderStyle: 'hidden',
                        fontSize: '15px'
                      }}
                      {...params}
                    />
                  )}
                  value={messageSelected}
                />
              </Box>
            )}
            {messageSelected && (
              <Box
                alignItems="start"
                display="flex"
                flexDirection="column"
                justifyContent="start"
                width="100%"
              >
                <Box pt={2}>
                  <SubTitle>{t('LISTING_PAGES.message')}</SubTitle>
                </Box>

                {createMode ? (
                  <RegularInput
                    multiline
                    customStyle={{ width: '100%' }}
                    onChange={(message) =>
                      setMessageSelected({ ...messageSelected, message })
                    }
                    value={messageSelected.message || ''}
                  />
                ) : (
                  <Paper
                    sx={{
                      alignItems: 'start',
                      display: 'flex',
                      mb: 2,
                      mt: 2,
                      p: 1,
                      width: '100%'
                    }}
                  >
                    <Typography variant="primary">
                      {messageSelected.message && messageSelected.message !== ''
                        ? messageSelected.message
                        : `${t('LEAD.CREATE.SERVICES.ADD_TASK_MODAL.noData')}`}
                    </Typography>
                  </Paper>
                )}

                <Box width="100%">
                  {createMode && (
                    <Box>
                      <Box alignItems="center" display="flex" pb={2} pt={2}>
                        <Checkbox
                          checked={addToCatalog}
                          onChange={(event) => {
                            setAddToCatalog(!addToCatalog);
                          }}
                        />
                        <SubTitle>
                          {t(
                            'LEAD.CREATE.SERVICES.ADD_TASK_MODAL.addToCatalog'
                          )}
                        </SubTitle>
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
            )}
            <Box display="flex" mb={2} mt={3} position="relative" width="100%">
              <Button
                fullWidth
                onClick={() => setAuxManualClose(true)}
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  fontSize: '1.2rem',
                  gap: 2,
                  m: 1
                }}
                variant="cancel"
              >
                {t('MODAL.cancel')}
              </Button>
              <Button
                fullWidth
                disabled={!messageSelected?.message || !messageSelected?.title}
                onClick={() => setFireAddMessage(true)}
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  fontSize: '1.2rem',
                  gap: 2,
                  m: 1
                }}
                variant="primary"
              >
                {t('LEAD.CREATE.PROPOSAL.addMessage')}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      {fireConfirmExit && (
        <ConfirmModal
          modalText={t('MODAL.confirmModalExitTask')}
          modalType="proposalMessagesTemplate"
          setSentFlag={setShowOkModal}
          setShowModal={setFireConfirmExit}
          setShowOkModal={setFireClose}
        />
      )}
    </Container>
  );
};

export default ProposalMessagesModal;
